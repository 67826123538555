import { updateAttributes } from "../lib/growthbook";

export const SESSION_STORAGE_KEYS = {
  organizationId: "texture_connect:organizationId",
  tags: "texture_connect:tags",
  sessionStarted: "texture_connect:session_started",
  hostOrigin: "texture_connect:host_origin",
  state: "texture_connect:state",
} as const;

const SESSION_STORAGE_GROWTHBOOK_ATTRIBUTE_KEYS: string[] = [
  SESSION_STORAGE_KEYS.organizationId,
  SESSION_STORAGE_KEYS.tags,
];

function get(key: (typeof SESSION_STORAGE_KEYS)[keyof typeof SESSION_STORAGE_KEYS]): string | null {
  return sessionStorage.getItem(key);
}

function set(key: (typeof SESSION_STORAGE_KEYS)[keyof typeof SESSION_STORAGE_KEYS], value: string): void {
  sessionStorage.setItem(key, value);

  // Maybe not the best spot,
  // check if we should update growthbook attributes
  if (SESSION_STORAGE_GROWTHBOOK_ATTRIBUTE_KEYS.includes(key)) {
    updateAttributes();
  }
}

export const sessionStorageService = {
  get,
  set,
};
