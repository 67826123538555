import { useEffect, useState } from "react";
import { analytics } from "../../analytics";
import { Manufacturer } from "../../interfaces";
import { useLocationWrapper } from "../../hooks/useLocationWrapper";

import Button from "../ButtonComponent";
import { SESSION_STORAGE_KEYS, sessionStorageService } from "../../services/sessionStorage";

interface IState extends Manufacturer {
  state: string;
  redirectUrl: string;
  redirectConfig?: {
    copy: string;
    delay: number;
  };
}

export function ConnectManufacturer() {
  const { state } = useLocationWrapper<IState>();
  const redirectUrl = state?.redirectUrl || "";
  const redirectConfig = state?.redirectConfig || {
    delay: 0,
    copy: undefined,
  };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (analytics && analytics.page) {
      analytics.page("connect manufacturer");
    }

    setIsLoading(false);
  }, []);

  const handleLogin = () => {
    setIsLoading(true);

    setTimeout(() => {
      window.location.assign(redirectUrl);
    }, redirectConfig.delay);
  };

  sessionStorageService.set(SESSION_STORAGE_KEYS.state, state?.state || "");

  return (
    <>
      <div className="w-full flex flex-col items-center gap-8 mb-8 lg:mb-0">
        <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-center">
          Sign in to your {state.displayName} account
        </div>
        <img src={state.logoUrl} alt={`${state.displayName} Logo`} className="w-48 tp:w-20" />
      </div>
      <div className="w-full sm:w-[30rem] flex flex-col items-center gap-8 tp:gap-4">
        <div className="text-xs sm:text-sm text-texturePennBlue text-center">
          Clicking &quot;Sign In&quot; below will direct you to {state.displayName}&apos;s website to sign in into your
          account
          <br />
          <br />
          You will return here afterwards
        </div>
        <div className="w-full sm:w-[30rem]">
          <Button onClick={handleLogin} disabled={!redirectUrl} loading={isLoading}>
            Sign In
          </Button>
        </div>
      </div>
    </>
  );
}
