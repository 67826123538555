import TextInput from "../TextInput";
import usePasswordInput, { PasswordInputProps } from "./hooks/use-password-input";
import RightIcon from "./components/RightIcon";
import { Container, Rectangles } from "./style";

export type { PasswordInputProps } from "./hooks/use-password-input";

function PasswordInput(props: PasswordInputProps): JSX.Element {
  const { strength, textInputProps, type, toggleType, text, label } = usePasswordInput(props);

  return (
    <Container>
      <TextInput
        {...textInputProps}
        label={label}
        type={type}
        iconRight={
          <RightIcon
            isShowingPlainText={type === "text"}
            toggleType={toggleType}
            forgotPasswordCallback={props.forgotPasswordCallback}
          />
        }
      />
      {!props.forgotPasswordCallback && !props.hideStrength && (
        <>
          <Rectangles strength={strength} />
          <div className="text-xs font-normal text-texturePennBlue">{text}</div>
        </>
      )}
    </Container>
  );
}

export default PasswordInput;
