import { PropsWithChildren, useEffect, useMemo } from "react";
import { Outlet, useParams, useLocation, useNavigate } from "react-router";
import { useGetNextState } from "../../hooks/useGetNextState";
import { onBeforeUnloadHandler } from "../../utils";
import { usePreventDeepLink } from "../../hooks/usePreventDeepLink";
import { ConnectLoading } from "./ConnectLoading";

import caretLeft from "../../assets/icons/caretLeft.svg";
import Button from "../ButtonComponent";
import Counter from "../Counter";

interface Props extends PropsWithChildren {
  hasError?: boolean;
}

export function ConnectLayout({ children, hasError }: Props) {
  const { linkToken = "" } = useParams();
  const location = useLocation();
  const { loading } = useGetNextState(linkToken);

  usePreventDeepLink();

  useEffect(() => {
    if (window.opener && window.opener !== window) {
      window.addEventListener("beforeunload", onBeforeUnloadHandler);
    }
  }, []);

  // hack for now
  const hasHeader = !hasError && !location.pathname.includes("error") && location.pathname !== `/${linkToken}`;

  return (
    <div className="flex flex-col justify-center w-screen lg:h-screen">
      <div
        className="overflow-auto mx-auto bg-white shadow lg:rounded-lg 
        w-full lg:max-w-[900px]
        min-h-screen lg:min-h-0 d:min-h-[700px] d:h-5/6 md:max-h-[calc(100vh-4rem)]
        py-10 px-4 lg:px-10"
      >
        {loading ? (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <ConnectLoading />
          </div>
        ) : (
          <div className="overflow-hidden w-full h-full flex flex-col items-center justify-start">
            {hasHeader && <Header />}
            <div className="overflow-auto w-full grow flex flex-col items-center justify-between gap-8 tp:gap-4">
              {children || <Outlet />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// TODO: extract
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { linkToken = "" } = useParams();
  const hasBackButton =
    location.pathname !== `/${linkToken}` &&
    location.pathname !== `/${linkToken}/intro` &&
    location.pathname !== `/${linkToken}/success` &&
    location.pathname !== `/${linkToken}/manufacturerUnsupported` &&
    location.pathname !== `/${linkToken}/manufacturers`;

  const handleBackClick = () => {
    navigate(-1);
  };

  const activeStep = useMemo(() => {
    if (location.pathname === `/${linkToken}/intro`) return 0;
    if (location.pathname.includes("location")) return 1;
    if (location.pathname.includes("manufacturers")) return 2;
    if (location.pathname.includes("oauth:code")) return 2;
    if (location.pathname === `/${linkToken}/success`) return 3;
    if (location.pathname === `/${linkToken}/manufacturerUnsupported`) return 3;
    if (location.pathname === `/${linkToken}/manufacturerCaptureSerial`) return 3;
    return 0;
  }, [location.pathname]);

  const steps = useMemo(() => {
    const first = "Intro";
    const second = location.pathname.includes("manufacturers/") ? "Sign In" : "Manufacturer";
    const third = location.pathname.includes("location") ? "Location" : "Devices";
    return [first, second, third];
  }, [location.pathname]);

  return (
    <div className="w-full shrink-0 grid grid-cols-3 mb-9 lg:mb-5 h-lg:lg:mb-9">
      <div>
        {hasBackButton && (
          <>
            <div className="hidden lg:block mt-2">
              <Button link onClick={handleBackClick} iconLeft={<img src={caretLeft} alt="left" className="w-4 h-4" />}>
                Back
              </Button>
            </div>
            <div className="lg:hidden mt-2">
              <Button
                link
                onClick={handleBackClick}
                iconLeft={<img src={caretLeft} alt="left" className="w-6 h-6" />}
              />
            </div>
          </>
        )}
      </div>
      <Counter activeStep={activeStep} steps={steps} />
    </div>
  );
};
