import React, { InputHTMLAttributes } from "react";
import { classNames } from "../../utils";

interface ContainerProps {
  hidden?: boolean;
  invalid?: boolean;
  isFocused?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  widthOfLabel?: number;
  hasLabel?: boolean;
  hasLeftIcon?: boolean;
  hasValue?: boolean;
}

export function Container(props: React.PropsWithChildren<ContainerProps>) {
  const className = classNames(
    "w-full relative flex py-1 px-2.5 bg-transparent flex-row mt-2 items-center justify-start border-2 border-t-0 h-14 rounded-md transform-texture gap-2",
    props.isFocused ? "border-textureIris" : "border-texturePennBlue/[0.26]",
    props.invalid && "border-textureRed",
    props.hidden && "hidden",
  );

  return (
    <div onClick={props.onClick} className={className}>
      <TopBorder
        isFocused={props.isFocused || false}
        widthOfLabel={props.widthOfLabel}
        hasLeftIcon={props.hasLeftIcon}
        hasValue={props.hasValue}
        hasLabel={props.hasLabel}
        invalid={props.invalid}
      />
      {props.children}
    </div>
  );
}

const topBorderClass = "h-0 border-t border-t-2 transform-texture";

interface TopBorderProps {
  isFocused: boolean;
  invalid?: boolean;
  hasValue?: boolean;
  widthOfLabel?: number;
  hasLeftIcon?: boolean;
  hasLabel?: boolean;
}

function TopBorder({ isFocused, hasValue, widthOfLabel = 0, hasLeftIcon, hasLabel, invalid }: TopBorderProps) {
  const classNameLeft = classNames(
    "shrink-0",
    topBorderClass,
    isFocused ? "border-textureIris" : "border-texturePennBlue/[0.26]",
    hasLeftIcon ? "w-10" : "w-2",
    invalid && "border-textureRed",
  );
  const classNameRight = classNames(
    "w-full",
    topBorderClass,
    isFocused ? "border-textureIris" : "border-texturePennBlue/[0.26] ml-0",
    invalid && "border-textureRed",
  );
  return (
    <div className="absolute left-px right-px top-0 flex h-2 flex-row overflow-hidden rounded-md bg-transparent">
      <div className={classNameLeft} />
      <div
        className={classNameRight}
        style={{
          marginLeft: hasLabel && (isFocused || hasValue) ? widthOfLabel + 20 : 0,
        }}
      />
    </div>
  );
}

interface LabelProps {
  invalid?: boolean;
  required?: boolean;
  focused: boolean;
  hasLeftIcon?: boolean;
  hasRightIcon?: boolean;
  htmlFor?: string;
}

export const StyledLabel = React.forwardRef<HTMLLabelElement | null, React.PropsWithChildren<LabelProps>>(
  (props, ref) => {
    return (
      <label
        ref={ref}
        htmlFor={props.htmlFor}
        className={classNames(
          "transform-texture pointer-events-none absolute left-4 top-3.5 z-10 block transform bg-transparent text-base font-normal leading-6 text-texturePennBlue",
          props.invalid && "text-textureRed",
          props.required && `after:content-['*']`,
          props.focused && "-translate-y-6 text-xs",
          props.hasLeftIcon && "ml-8",
          props.hasRightIcon && "mr-8",
        )}
      >
        {props.children}
      </label>
    );
  },
);

interface StyledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  withForm?: boolean;
}

export const StyledInput = React.forwardRef<HTMLInputElement | null, React.PropsWithChildren<StyledInputProps>>(
  (props, ref) => {
    const className = classNames(
      "w-full p-0 border-0 bg-transparent text-textureGray placeholder:text-textureGray/[0.5] border-transparent focus:border-transparent focus:ring-0 outline-none",
    );

    const styledInputProps: InputHTMLAttributes<HTMLInputElement> = {
      ...props,
      ...(props.withForm && { value: props.value }),
    };

    return <input className={className} {...styledInputProps} ref={ref} />;
  },
);

StyledLabel.displayName = "StyledLabel";
StyledInput.displayName = "StyledInput";
