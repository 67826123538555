import { useEffect } from "react";
import { onBeforeUnloadHandler, sendHostMessage } from "../../utils";
import { analytics } from "../../analytics";
import { useLocationWrapper } from "../../hooks/useLocationWrapper";

import GreenCheckLogo from "../../assets/green-check-logo.svg";
import Button from "../ButtonComponent";
import { useGetNextState } from "../../hooks/useGetNextState";
import { useParams } from "react-router";

// we are missing the device name, display_name prop or something here to be used in the title
interface IState {
  name: string;
  redirectUrl: string;
  scopedKey: string;
  customerId: string;
  additionalLinks: Array<{
    text: string;
    url: string;
  }>;
}

export function ConnectSuccess() {
  const { linkToken = "" } = useParams();
  const { state } = useLocationWrapper<IState>();
  const { getNextState } = useGetNextState(linkToken);

  useEffect(() => {
    if (analytics && analytics.page) {
      analytics.page("connect success");
    }
  }, []);

  const redirectUrl = state?.redirectUrl || "";
  const scopedKey = state?.scopedKey || "";
  const customerId = state?.customerId || "";
  const inPopup = !!window.opener && window.opener !== window;
  const shouldRenderContinue = !!(inPopup || redirectUrl);

  const handleContinue = () => {
    if (inPopup) {
      window.removeEventListener("beforeunload", onBeforeUnloadHandler);
      sendHostMessage({
        type: "scoped-key",
        data: scopedKey,
      });
      window.close();
    } else {
      const url = new URL(redirectUrl);
      url.searchParams.append("texture_scoped_key", scopedKey);
      url.searchParams.append("texture_customer_id", customerId);
      window.location.assign(url);
    }
  };

  const handleRepeat = () => {
    getNextState("success", {
      type: "continue",
    });
  };

  const handleLinkClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="hidden tp:block d:block"></div>
      <div className="flex flex-col items-center justify-between gap-8 tp:gap-4 max-w-[40rem]">
        <img src={GreenCheckLogo} className="mx-auto w-24 h-24" alt="success-logo" />
        <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-center">
          Your device has been successfully connected to {state.name}.
        </div>
        {/* <div className="text-sm text-gray-600">
        You have successfully connected your account.
        {!inPopup && redirectUrl && ` Redirecting back to your application...`}
      </div> */}
      </div>
      <div className="w-full sm:w-[30rem] flex flex-col items-center gap-8 tp:gap-4">
        {(state.additionalLinks || []).map((link) => (
          <Button key={link.url} onClick={() => handleLinkClick(link.url)} link secondary>
            {link.text}
          </Button>
        ))}
        <Button onClick={handleRepeat} link secondary>
          Connect another device
        </Button>
        {shouldRenderContinue && <Button onClick={handleContinue}>Continue</Button>}
      </div>
    </>
  );
}
