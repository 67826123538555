import { useEffect, useState } from "react";
import { analytics } from "../../analytics";
import { useLocationWrapper } from "../../hooks/useLocationWrapper";

import Button from "../ButtonComponent";
import { useParams } from "react-router";
import { Manufacturer } from "../../interfaces";
import TextInput from "../TextInput";
import GreenCheckLogo from "../../assets/green-check-logo.svg";

// we are missing the device name, display_name prop or something here to be used in the title
interface IState extends Manufacturer {
  redirectUrl: string;
}

const apiUrl = import.meta.env.VITE_CONNECT_API_BASE_URL || "http://localhost:3000";

const getSerialNumberLabel = (slug: string, displayName: string) => {
  switch (slug) {
    case "franklinwh":
      return `${displayName} aGate serial number`;
    default:
      return `${displayName} serial number`;
  }
};

const getSerialNumberContent = (slug: string, displayName: string) => {
  switch (slug) {
    case "franklinwh":
      return (
        <div>
          <div className="text-texturePennBlue text-center mb-5">
            To find your aGate serial number please open the {displayName} app or locate the number directly on your
            device.
          </div>
        </div>
      );
    default:
      return (
        <div>
          <div className="text-texturePennBlue text-center mb-5">
            To find your serial number please open the {displayName} app or locate the number directly on your device.
          </div>
          <div className="text-texturePennBlue text-center mb-5">
            If you have any questions, please{" "}
            <a className="text-textureIris cursor-pointer" target="_blank" href="https://www.texturehq.com/contact-us">
              reach out to us
            </a>
            .
          </div>
        </div>
      );
  }
};

export function ConnectManufacturerSerialNumberCapture() {
  const { linkToken = "" } = useParams();
  const { state } = useLocationWrapper<IState>();
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [serialNumberCaptured, setSerialNumberCaptured] = useState<boolean>(false);

  useEffect(() => {
    if (analytics && analytics.page) {
      analytics.page("connect unsupported");
    }
  }, []);

  const handleRepeat = () => {
    setSerialNumber("");
    setSerialNumberCaptured(false);
  };

  const handleContinue = () => {
    const url = new URL(state.redirectUrl);
    window.location.replace(url);
  };

  const submitSerialNumber = () => {
    const processTransaction = async () => {
      // make call here to send the serial number for manufacturer to the server
      try {
        await fetch(`${apiUrl}/v1/states/${linkToken}/postManufacturerSerialNumberCapture/${state.slug}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            serialNumber: serialNumber,
          }),
        });

        setSerialNumberCaptured(true);
      } catch (err) {
        console.error(err);
      }
    };

    processTransaction();
  };

  return serialNumberCaptured ? (
    <>
      <div className="hidden tp:block d:block"></div>
      <div className="flex flex-col items-center justify-between gap-8 tp:gap-4 max-w-[40rem]">
        <img src={GreenCheckLogo} className="mx-auto w-24 h-24" alt="success-logo" />
        <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-center">
          We are processing your connection to {state.displayName}.
        </div>
      </div>
      <div className="w-full sm:w-[30rem] flex flex-col items-center gap-8 tp:gap-4">
        <Button onClick={handleRepeat} link secondary>
          Connect another device
        </Button>
        {state.redirectUrl && <Button onClick={handleContinue}>Continue</Button>}
      </div>
    </>
  ) : (
    <>
      <div className="hidden tp:block d:block"></div>
      <div className="flex flex-col items-center justify-between gap-8 tp:gap-4 max-w-[40rem]">
        <img src={state.logoUrl} className="mx-auto w-24 h-24" alt="success-logo" />
        <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-center">
          {getSerialNumberLabel(state.slug, state.displayName)}
        </div>
        <div className="w-full sm:w-[30rem]">
          <TextInput label="SN:" inputMode="text" value={serialNumber} onChange={(serial) => setSerialNumber(serial)} />
        </div>
        {getSerialNumberContent(state.slug, state.displayName)}
      </div>
      <div className="w-full sm:w-[30rem]">
        <Button onClick={submitSerialNumber} disabled={serialNumber.length < 6}>
          Submit
        </Button>
      </div>
    </>
  );
}
